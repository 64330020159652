window.bahlsen = (window.bahlsen || {});

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.Mobile = (function Mobile() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    mobileMenu();

    return true;

  };

  var mobileMenu = function () {


    let mobileStatus = true;
    const $burger = document.querySelector('.switch-mobile');
    const $close = document.querySelector('.close');

    const $menuScroll =  [].slice.call(document.querySelectorAll('.scrollto-menu'));

    $burger.addEventListener('click', function() {

      let _mobile_menu = document.querySelector('.mobile-menu'),
       _icon_open = document.querySelector('.open'),
       _icon_close = document.querySelector('.close');
       console.log(_icon_close);

      if(mobileStatus == true) {
            _icon_open.style.display = 'none';
            _icon_close.style.display = 'block';
            _mobile_menu.classList.add('active');
            mobileStatus = false;

        } else if(mobileStatus == false) {
            _icon_open.style.display = 'inline-block';
            _icon_close.style.display = 'none';
            _mobile_menu.classList.remove('active');
            mobileStatus = true;
        }
    });


    $menuScroll.forEach(function ($button) {

      let _mobile_menu = document.querySelector('.mobile-menu'),
        _icon_open = document.querySelector('.open'),
        _icon_close = document.querySelector('.close');

      $button.addEventListener('click', function (e) {
        e.preventDefault();
        console.log('clicked');
        _icon_open.style.display = 'inline-block';
        _icon_close.style.display = 'none';
        _mobile_menu.classList.remove('active');
        mobileStatus = true;
      });
    });

    $close.addEventListener("click", () => {

    });

  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
