window.bahlsen = (window.bahlsen || {});

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.Map = (function Map() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    map();

    return true;

  };

  var map = function () {



        var mapHolder = document.querySelector('#map');
        if( mapHolder ) {
          var marker_Lat = mapHolder.dataset.markerLat;
          var marker_Lng =  mapHolder.dataset.markerLong;

          var mapcenter_Lat = mapHolder.dataset.mapcenterLat;
          var mapcenter_Lng =  mapHolder.dataset.mapcenterLong;

          var app_adress =  mapHolder.dataset.adress;

          var isDraggable = true;

          var mapSettings = {
              linkToMap : 'https://www.google.ca/maps/dir/\'\'/'+encodeURIComponent(app_adress)+'/'
              // pinImage : '/assets/images/google-marker.png'
          };


      
         window.addEventListener("resize",function(){

              mapcenter_Lat = marker_Lat;
              mapcenter_Lng =  marker_Lng;
              var w =  window.innerWidth;

              if (w <= 799) {
                isDraggable = false;
              }
          });

        if (mapHolder) {
            //MAP
            var initialize = function() {
                var mapOptions = {
                    zoom: 13,
                    scrollwheel: false,
                    overviewMapControl: false,
                    panControl: false,
                    draggable : isDraggable,
                    disableDefaultUI: true,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    center: new google.maps.LatLng( mapcenter_Lat, mapcenter_Lng ),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "32"
            },
            {
                "lightness": "-3"
            },
            {
                "visibility": "on"
            },
            {
                "weight": "1.18"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-70"
            },
            {
                "lightness": "14"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "100"
            },
            {
                "lightness": "-14"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "lightness": "12"
            }
        ]
    }
]
                };

                var map = new google.maps.Map(document.getElementById('map'),mapOptions);

                var myLatLng = new google.maps.LatLng( marker_Lat, marker_Lng );

                var hoffmanMarker = new google.maps.Marker({
                  position: myLatLng,
                  map: map,
                  icon: mapSettings.pinImage
              });
              google.maps.event.addListener(hoffmanMarker, 'click', function () {
                  window.open(mapSettings.linkToMap, '_blank');
              });
               //Retrive the center location
              google.maps.event.addListener(map, "center_changed", function() {
                console.log( map.getCenter().toUrlValue() );
              });
            };

            google.maps.event.addDomListener(window, 'load', initialize);

        }
      }


  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
