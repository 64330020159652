window.bahlsen = (window.bahlsen || {});

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.Modal = (function Modal() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    modal();

    return true;

  };

  var modal = function () {

    let _action = document.querySelector('.show-overlay-form'),
      _overlay = document.querySelector('.overlay-form'),
      _close = document.querySelector('.close'),
      _overlayFilter = document.querySelector('.overlay-filter');

    if( _action ) {
      _action.addEventListener('click', function(e) {
        e.preventDefault();
        _overlay.classList.add('active');
      });

      _close.addEventListener('click', function(e) {
         _overlay.classList.remove('active');
      });

      _overlayFilter.addEventListener('click', function(e) {
         _overlay.classList.remove('active');
      });
    }

  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
