window.bahlsen = (window.bahlsen || {});

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.Search = (function Search() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    searchBar();

    const $menuScroll =  [].slice.call(document.querySelectorAll('.scrollto-menu'));

    $menuScroll.forEach(function ($button) {
      $button.addEventListener('click', function (e) {
        e.preventDefault();
        const element = document.querySelector($button.getAttribute("href"));
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        console.log(elementPosition)
        console.log(elementPosition)

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      });
    });

    return true;

  };

  var searchBar = function () {

    let _icon_search = document.querySelector('.link.search');
    _icon_search.addEventListener('click', function() {

      let _icon_search = document.querySelector('.icon_search'),
          _icon_close = document.querySelector('.icon_close'),
          _search = document.querySelector('#search-input');

      if ( _search.classList.contains('open') ) {

          _icon_close.style.display = 'none';
          _icon_search.style.display = 'inline-block';
          _search.classList.remove('open');

      } else {

          _icon_search.style.display = 'none';
          _icon_close.style.display = 'inline-block';
          _search.classList.add('open');
      }

    });

  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
