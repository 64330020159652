window.site = (window.site || {});

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
site.HelperReminder = (function HelperReminder($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // var $myElement = $('#myElement');
    let $myElement = document.querySelector('#myElement');
    // var $navLinks = $('.nav-links');
    let $navLinks = document.querySelectorAll('.nav-links');
    // var $label = $myElement.find('p'); || var $label = $('p', $myElement);
    let $label = $myElement.querySelector('p');
    // var attr = $label.attr('data-my-attribute');
    let attr = $label.getAttribute('data-my-attribute');

    // $.each($navLinks, function(index, val) {
    //   var item = $(this);
    // });

    // $navLinks.on('click', function(event) {
    //   event.preventDefault();
    //   $label.text('Success!');
    //    [...]
    //   $myElement.addClass('is-active');
    // });
    $navLinks.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        $label.textContent = 'Success!';
        $myElement.innerHTML += `
          <div>
            <p data-custom-attr="${attr}" >My Html!</p>
          </div>
        `;
        $myElement.classList.add('is-active');
      });
    });

    ES6ThisPlease();

    return true;

  };

  var ES6ThisPlease = function () {

    let nums = [1, 2, 3, 4, 5];

    nums.forEach((v) => console.log(v));

    // Food is a base class
    class Food {
      constructor (name, protein, carbs, fat) {
        this.name = name;
        this.protein = protein;
        this.carbs = carbs;
        this.fat = fat;
      }

      toString () {
        return `${this.name} | ${this.protein}g P :: ${this.carbs}g C :: ${this.fat}g F`
      }

      print () {
        console.log( this.toString() );
      }
    }

    let chicken_breast = new Food('Chicken Breast', 26, 0, 3.5);

    chicken_breast.print(); // 'Chicken Breast | 26g P :: 0g C :: 3.5g F'
    console.log(chicken_breast.protein); // 26 (LINE A)
  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
